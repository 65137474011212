<template>
	<div>
		<a-spin :spinning="loading">
			<a-form class="label-width ui-form" ref="form" :model="formModal" layout="inline" @finish="onSearch">
				<a-form-item label="资产编号" class="ui-form__item">
					<a-row>
						<a-col :span="11">
							<a-input placeholder="请输入资产编号数字部分" v-model:value="formModal.startCode"></a-input>
						</a-col>
						<a-col :span="2" class="ui-form__middle">到</a-col>
						<a-col :span="11">
							<a-input placeholder="请输入资产编号数字部分" v-model:value="formModal.endCode"></a-input>
						</a-col>
					</a-row>
				</a-form-item>
				
				<a-form-item label="取得时间" class="ui-form__item">
					<!-- 缺 -->
					 <a-range-picker @change="onChangeFun" :value="clearTime" show-time/>
				</a-form-item>
				
				<a-form-item label="价格" class="ui-form__item">
					<a-row>
						<a-col :span="11">
							<a-input v-model:value="formModal.startAmount"></a-input>
						</a-col>
						<a-col :span="2" class="ui-form__middle">到</a-col>
						<a-col :span="11">
							<a-input v-model:value="formModal.endAmount"></a-input>
						</a-col>
					</a-row>
				</a-form-item>
				
				<a-form-item label="资产名称" class="ui-form__item">
					<a-input placeholder="请输入资产名称" v-model:value="formModal.name"></a-input>
				</a-form-item>
				
				<a-form-item label="资产分类" class="ui-form__item">
					<a-cascader v-model:value="formModal.assetsParameterId" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
					<!-- <a-select v-model:value="formModal.assetsParameterId" allow-clear style="width: 190px;">
						<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select> -->
				</a-form-item>
				
				<a-form-item label="使用状况" class="ui-form__item">
					<a-select placeholder="请选择使用状况" v-model:value="formModal.conditionType" style="width: 190px;">
						<a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
					</a-select>	
				</a-form-item>
				
				<a-form-item label="取得方式" class="ui-form__item">
					<a-select placeholder="请选择取得方式" v-model:value="formModal.gainType"  style="width: 190px;">
						<a-select-option v-for="(item, index) in $store.state.count.methodList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
					</a-select>	
				</a-form-item>
				
				<a-form-item label="使用部门" class="ui-form__item">
					<a-select placeholder="请选择使用部门" v-model:value="formModal.departmentId" style="width: 190px;">
						<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				
				<a-form-item label="使用人" class="ui-form__item">
					<a-input placeholder="请输入使用人" v-model:value="formModal.nickname"></a-input>
				</a-form-item>
				
				<a-form-item label="存放地点" class="ui-form__item">
					<!-- 缺 -->
					<a-input placeholder="请输入存放地点" v-model:value="formModal.place"></a-input>
				</a-form-item>
				
			<!-- 	<a-form-item label="品牌" class="ui-form__item">
					缺
					<a-input ></a-input>
				</a-form-item> -->
				
				<!-- <a-form-item label="产品序列号" class="ui-form__item">
					<a-input></a-input>
				</a-form-item> -->
				
				<a-form-item label="设备用途" class="ui-form__item">
					<a-select placeholder="请选择设备用途" allowClear v-model:value="formModal.useType" style="width: 190px;">
						<a-select-option v-for="(item, index) in $store.state.count.usageList" :key="item.id" :value="item.code">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>
				
				<div style="width: 100%;text-align: center;">
					<a-button style="margin-right:30px;" @click="onReset">重 置</a-button>
					<a-button type="primary" html-type="submit" @click="onSearch">搜 索</a-button>
				</div>
			</a-form>
			<div class="ui-actionList">
				<a-button type="primary" v-permission="['pc_print_all']" @click="onPrintAll">打印全部标签</a-button>
			</div>
			
			<div>
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" @rowSelected="rowSelected" :searchData="searchData" rowKey="id" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<!-- conditionType -->
						<template v-if="column.key === 'conditionType'">
							<span v-if="record.conditionType===0"></span>
							<span v-if="record.conditionType===1">在用</span>
							<span v-if="record.conditionType===2">出租</span>
							<span v-if="record.conditionType===3">出借</span>
							<span v-if="record.conditionType===4">闲置</span>
							<span v-if="record.conditionType===5">待处置</span>
						</template>
						<template v-if="column.key === 'useType'">
							<span v-if="record.useType===0"></span>
							<span v-if="record.useType===1">办公</span>
							<span v-if="record.useType===2">教学</span>
							<span v-if="record.useType===3">培训</span>
							<span v-if="record.useType===4">执法</span>
							<span v-if="record.useType===5">窗口</span>
							<span v-if="record.useType===6">专用</span>
							<span v-if="record.useType===7">其它</span>
							<span v-if="record.useType===8">医疗</span>
							<span v-if="record.useType===9">科研</span>
						</template>
						<template v-if="column.key === 'gainType'">
							<span v-if="record.gainType===0"></span>
							<span v-if="record.gainType===1">新购</span>
							<span v-if="record.gainType===2">调拨</span>
							<span v-if="record.gainType===3">接受捐赠</span>
							<span v-if="record.gainType===4">自建</span>
							<span v-if="record.gainType===5">置换</span>
							<span v-if="record.gainType===6">盘盈</span>
							<span v-if="record.gainType===7">自行研制</span>
							<span v-if="record.gainType===8">其它</span>
						</template>
						<template v-if="column.key === 'incomeTime'">
							{{ transDateTime(record.incomeTime) }}
						</template>
						<template v-if="column.key === 'gainTime'">
							{{ transDateTime(record.gainTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" @click="onPrint(record)">打印标签</a-button>
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" 
					class="table"
					:columns="columns" 
					:data-source="list" 
					:pagination="pagination" 
					bordered 
					size="small"
					:scroll="{ x: 1500 }"
					:rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'incomeTime'">
							{{ transDateTime(record.incomeTime) }}
						</template>
						<template v-if="column.key === 'gainTime'">
							{{ transDateTime(record.gainTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" @click="onPrint(record)">打印标签</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
	</div>
</template>

<script>
	import cTable from '@/components/cTable/index.vue';
	import { getSearchListAssets, printLabel } from '@/service/modules/print.js';
	export default {
		components: {
			cTable,
		},
		data() {
			return {
				getDataFun: getSearchListAssets,
				// rowSelectionType: 'checkbox',
				loading: false,
				formModal: {},
				searchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				ids: '',
				clearTime: [], // 存储时间 用来清空重置
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index',
					width: 100
				},{
					title: '资产分类',
					align: 'center',
					dataIndex: "classifyName"
				},{
					title: '资产编号',
					align: 'center',
					dataIndex: "code"
				},{
					title: '资产名称',
					align: 'center',
					dataIndex: "name"
				},{
					title: '价值',
					align: 'center',
					dataIndex: "amount"
				},{
					title: '使用状况',
					align: 'center',
					dataIndex: "conditionType",
					key: "conditionType"
				},{
					title: '设备用途',
					align: 'center',
					dataIndex: "useType",
					key: "useType"
				},{
					title: '使用部门',
					align: 'center',
					dataIndex: "departmentName"
				},{
					title: '使用人',
					align: 'center',
					dataIndex: "user"
				},{
					title: '存放地',
					align: 'center',
					dataIndex: "place"
				},{
					title: '取得方式',
					align: 'center',
					dataIndex: "gainType",
					key: "gainType"
				},{
					title: '取得时间',
					align: 'center',
					key: 'gainTime',
					dataIndex: "gainTime",
					width: 200
				},{
					title: '财务入账时间',
					align: 'center',
					key: 'incomeTime',
					dataIndex: "incomeTime",
					width: 200
				},{
					title: '操作',
					key: 'action',
					fixed: 'right',
					align: 'center',
					width: 100
				}],
				// selectedRowKeys: [],
				// selectedRowData: [],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				this.searchData.startCode = Number(this.searchData.startCode)
				this.searchData.endCode = Number(this.searchData.endCode)
				this.searchData.assetsParameterId = this.searchData.assetsParameterId ? this.searchData.assetsParameterId[this.searchData.assetsParameterId.length - 1] : undefined;
				// this.pagination.current = 1;
				// setTimeout(()=>{
				// 	this.$refs.cTable.toQuery();
				// },1000)
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onReset() {
				this.$refs.form.resetFields();
				this.clearTime = []
				this.formModal = {}
				this.onSearch();
			},
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getSearchListAssets({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onPrint(item) {
				this.$confirm({
					title: '提示',
					content: "确定打印当前资产信息吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await printLabel({
								ids: item.id,
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$download(ret.data, '打印标签');
								// if (ret.data) {
								// 	window.open(ret.data)
								// }
								// this.$message.success('打印成功');
								this.onSearch();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			rowSelected(item) {
				this.ids = item.keys.join(',')
			},
			onPrintAll() {
				// let ids = this.selectedRowKeys.join(',')
				if(this.ids.length !== 0) {
					this.$confirm({
						title: '提示',
						content: "确定打印当前所选资产信息吗？",
						onOk: async ()=> {
							this.loading = true;
							try {
								let ret = await printLabel({
									ids:this.ids,
								})
								this.loading = false;
								if (ret.code === 200) {
									this.$download(ret.data, '打印标签');
									// if (ret.data) {
									// 	window.open(ret.data)
									// }
									// this.$message.success('打印成功');
									this.onSearch();
								}
							} catch(e) {
								this.loading = false;
							}
						}
					})
				}
			},
			// onSelectChange(record, selected) {
			// 	console.log("selected",selected)
			// 	if (selected) {
			// 		this.selectedListData.push(JSON.parse(JSON.stringify(record)));
			// 		if(this.selectedRowKeys.includes(record.id) === false) {
			// 			this.selectedRowKeys.push(record.id);
			// 		}
			// 	} else {
			// 		this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
			// 		this.selectedListData = this.selectedListData.filter(item => {
			// 			return item.id !== record.id;
			// 		});
			// 	}
			// 	console.log("this.selectedRowKeys",this.selectedRowKeys)
			// 	console.log("selectedListData",this.selectedListData)
			// },
			// onSelectAll(selected) {
			// 	console.log("selectedall",selected)
			// 	if (selected) {
			// 		this.list.forEach(item => {
			// 			if (this.selectedRowKeys.indexOf(item.id) === -1) {
			// 				this.selectedListData.push(JSON.parse(JSON.stringify(item)));
			// 				this.selectedRowKeys.push(item.id);
			// 			}
			// 		})
			// 	} else {
			// 		this.list.forEach(item => {
			// 			if (this.selectedRowKeys.indexOf(item.id) !== -1) {
			// 				this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
			// 				this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
			// 			}
			// 		})
			// 	}
			// 	console.log("this.selectedRowKeys",this.selectedRowKeys)
			// 	console.log("allSelectedListData",this.selectedListData)
			// },
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		padding: 20px;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-actionList {
		padding: 20px 0;
		text-align: right;
	}
</style>